import React from 'react'
import Loader from '../components/loader/loader'

const LoadingPage = () => {
  return (
    <div className='h-screen w-screen flex items-center justify-center'>
        <Loader />
    </div>
  )
}

export default LoadingPage