import axios from 'axios';
import { localStorageKeys } from './utils/local_storage_keys';
import { BASE_URL } from './utils/constants';
import { authService } from './services/auth_services';

// Create an instance of axios
const apiClient = axios.create({
  baseURL: BASE_URL,
})

// Request interceptor
apiClient.interceptors.request.use(
  async (config) => {
    // Get the token from localStorage
    config.headers['Accept'] = `application/json`;

    const token = localStorage.getItem(localStorageKeys.tokenKey);

    if (token) {
      // If a token is found, add it to the request headers
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const responseData = await authService.getToken();
        
        if (responseData.data.access_token) {
            const newToken = responseData.data.access_token;
            localStorage.setItem(localStorageKeys.tokenKey, newToken);
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
            // Return the result o f the retried request
            return apiClient(originalRequest);
        } else {
          // No token received - handle this scenario
          console.warn('No token received, redirecting to login.');
          // Optionally redirect to login page
          // window.location.href = '/login';
          return Promise.reject(new Error('No token received'));
        }
        // Update the Authorization header and retry the original request
      } catch (refreshError) {
        // Handle refresh token errors (e.g., redirect to login)
        console.error('Refresh token error:', refreshError);
        // Optionally redirect to login page
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
