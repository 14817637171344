import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './time_selector.css';

export const TimeSelectorComponent = ({ setStartDate, setEndDate, onDateChanged }) => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [timeframe, setTimeframe] = useState('day');
  const [customStartDate, setCustomStartDate] = useState(dayjs().startOf('day'));
  const [customEndDate, setCustomEndDate] = useState(dayjs().endOf('day'));

  const calculateDateRange = () => {
    switch (timeframe) {
      case "day":
        return { startDate: currentDate.startOf("day"), endDate: currentDate.endOf("day") };
      case "week":
        return { startDate: currentDate.startOf("week"), endDate: currentDate.endOf("week") };
      case "month":
        return { startDate: currentDate.startOf("month"), endDate: currentDate.endOf("month") };
      case "year":
        return { startDate: currentDate.startOf("year"), endDate: currentDate.endOf("year") };
      case "customize":
        return { startDate: customStartDate, endDate: customEndDate };
      default:
        return { startDate: currentDate.startOf("day"), endDate: currentDate.endOf("day") };
    }
  };

  useEffect(() => {
    const { startDate, endDate } = calculateDateRange();
    setStartDate(startDate);
    setEndDate(endDate);
  }, [currentDate, timeframe, customStartDate, customEndDate]);

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
    setCurrentDate(dayjs());
    if (newTimeframe === 'customize') {
      setCustomStartDate(currentDate.startOf('day'));
      setCustomEndDate(currentDate.endOf('day'));
    }
    onDateChanged();
  };

  const goBack = () => {
    switch (timeframe) {
      case "day":
        setCurrentDate(currentDate.subtract(1, "day"));
        break;
      case "week":
        setCurrentDate(currentDate.subtract(1, "week"));
        break;
      case "month":
        setCurrentDate(currentDate.subtract(1, "month"));
        break;
      case "year":
        setCurrentDate(currentDate.subtract(1, "year"));
        break;
      default:
        break;
    }
    onDateChanged();
  };

  const goForward = () => {
    switch (timeframe) {
      case "day":
        setCurrentDate(currentDate.add(1, "day"));
        break;
      case "week":
        setCurrentDate(currentDate.add(1, "week"));
        break;
      case "month":
        setCurrentDate(currentDate.add(1, "month"));
        break;
      case "year":
        setCurrentDate(currentDate.add(1, "year"));
        break;
      default:
        break;
    }
    onDateChanged();
  };

  const formatDate = () => {
    switch (timeframe) {
      case "day":
        return currentDate.format("MMMM D, YYYY");
      case "week":
        const startOfWeek = currentDate.startOf("week").format("MMMM D");
        const endOfWeek = currentDate.endOf("week").format("MMMM D, YYYY");
        return `${startOfWeek} - ${endOfWeek}`;
      case "month":
        return currentDate.format("MMMM YYYY");
      case "year":
        return currentDate.format("YYYY");
      case "customize":
        return `${customStartDate.format("MMMM D, YYYY")} - ${customEndDate.format("MMMM D, YYYY")}`;
      default:
        return currentDate.format("MMMM D, YYYY");
    }
  };

  return (
    <div>
      <div className='flex justify-center'>
        <div className="relative time-container flex">
          {["Day", "Week", "Month", "Year", "Customize"].map((label, index) => (
            <button
              key={index}
              className={`${timeframe === label.toLowerCase() && 'text-main active-date-option'} date-option font-semibold`}
              onClick={() => handleTimeframeChange(label.toLowerCase())}
            >
              {label}
            </button>
          ))}
          <div className='animation'></div>
        </div>
      </div>
      {timeframe === 'customize' ? (
        <div className="mt-5 flex justify-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={customStartDate}
              onChange={(newValue) => {
                setCustomStartDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className="mx-5"></div>
            <DatePicker
              label="End Date"
              value={customEndDate}
              onChange={(newValue) => {
                setCustomEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      ) : (
        <section className="mt-4 flex w-full justify-stretch">
          <div className="flex w-full justify-center">
            <button onClick={goBack}>
              <MdKeyboardArrowLeft size={23} />
            </button>
            <div className="flex-1 w-full md:max-w-96 flex items-center justify-center font-semibold ">{formatDate()}</div>
            <button onClick={goForward}>
              <MdKeyboardArrowRight size={23} />
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default TimeSelectorComponent;
