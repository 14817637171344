import axios from "axios";
import { BASE_URL } from "../utils/constants";
import apiClient from "../api_client";

const getToken = async () => {
  try {
    const body = {
        "client_id": "shopping_oauth_client",
        "client_secret": "shopping_oauth_secret"
    }
    const response = await axios.post(`${BASE_URL}/oauth2/token/client_credentials`, body);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
}

const register = async (email, password) => {
  try {
    const body = {
      "email": email,
      "password": password,
      "confirm": password,
      "agree": 1,
    };
    const response = await apiClient.post('/register', body);
    return response.data.data;
  } catch (error) {
    console.error('Error registering: ' , error);
    throw error;
  }
}

const signIn = async (email, password) => {
  try {
    const body = {
      "email": email,
      "password": password,
    };
    const response = await apiClient.post('/login/login', body);
    return response.data.data;
  } catch (error) {
    console.error('Error logging in: ' , error);
    throw error;
  }
}

const logout = async () => {
  try {
    const response = await apiClient.post('/logout');
    console.log(response.data);
  } catch (error) {
    console.error('Error logging in: ' , error);
  }
}

export const authService = {
    getToken,
    register,
    signIn,
    logout,
}