import React from "react";
import { LineChart } from "@mui/x-charts";
import dayjs from "dayjs";

const WeeklySessionsChart = ({ data }) => {
  // Array representing the number of sessions over the last 7 days
  function getLast7Days() {
    const today = new Date(); // Current date
    const dates = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today); // Create a new date object for today
      date.setDate(today.getDate() - i); // Subtract i days from the current date
      dates.push(date);
    }

    return dates;
  }

  return !data ? (
    <></>
  ) : (
    <LineChart
      xAxis={[
        {
          label: "Date",
          data: getLast7Days(),
          tickInterval: getLast7Days(),
          valueFormatter: (date) => dayjs(date).format("MMM D"),
        },
      ]}
      yAxis={[
        {
          label: "No. of Sessions",
          valueFormatter: (value) => value.toFixed(0),
          min: 0,
          max: Math.max(...data) + 1,
          tickNumber: Math.max(...data) + 3,
        },
      ]}
      series={[
        {
          label: "",
          data: data,
           color: "#069e20",
        },
      ]}
      height={400}
      width={400}
       slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default WeeklySessionsChart;
