import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const IntroPage = () => {
    const navigate = useNavigate();

    return (   
        <section className=' h-screen w-screen flex items-center justify-center px-5'>
            <div className='max-w-5xl flex flex-col items-stretch h-screen justify-between'>
                <article className='h-12'></article>
                <article className='flex items-center justify-center'>
                    <p className='text-4xl text-center font-bold'>
                        WE HELP YOU MANAGE ALL YOUR COACHING SESSIONS AND PAYMENTS WITH 1 CLICK
                    </p>
                </article>
                <article className='flex justify-center flex-col items-center'>
                    <div 
                        onClick={() => navigate('/login')}
                        className='bg-black hover:opacity-80 active:opacity-60 w-full md:w-3/5 cursor-pointer h-12 flex items-center justify-center rounded-3xl'>
                        <p className='text-white text-lg'>Login</p>
                    </div>
                     <div className='h-4'></div>
                    <div 
                        onClick={() => navigate('/register')}
                        className='border border-black cursor-pointer hover:bg-slate-100 active:bg-slate-200 w-full md:w-3/5 h-12 flex items-center justify-center rounded-3xl'>
                        <p className='text-black text-lg'>Register</p>
                    </div>
                    <div className='h-12'></div>
                </article>
            </div>

        </section>
       
    );

}

export default IntroPage