import { apiSlice } from './apiSlice';

export const traineeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrainees: builder.query({
      query: ({page, limit} = {}) => {
        let url = "https://clickcoach.app/index.php?route=rest/coach/trainee";
        if (page && limit) {
          url += `&page=${page}&limit=${limit}`;
        }
        return url;
      },
      transformResponse: (response) => response.data,
      providesTags: (result) => 
        result
          ? [...result?.trainees?.map(({ id }) => ({ type: 'Trainee', id })), { type: 'Trainee', id: 'LIST' }]
          : [{ type: 'Trainee', id: 'LIST' }],
    }),
    addTrainee: builder.mutation({
      query: (newTrainee) => ({
        url: `coach/trainee`,
        method: 'POST',
        body: newTrainee,
      }),
      invalidatesTags: [ { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'Sessions' },],
    }),
    editTrainee: builder.mutation({
      query: (newTrainee) => ({
        url: `coach/trainee/${newTrainee.id}`,
        method: 'PUT',
        body: newTrainee,
      }),
      invalidatesTags: [ { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'Sessions' },],
    }),
    deleteTrainee: builder.mutation({
      query: (id) => ({
        url: `coach/trainee/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) =>  [ { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id },
        { type: 'Sessions' },],
    }),
  }),
});

// Export hooks for the injected endpoints
export const { useGetTraineesQuery, useAddTraineeMutation, useEditTraineeMutation, useDeleteTraineeMutation } = traineeApi;
