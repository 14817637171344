import React from 'react'

const PageButtonNo = ({isSelected, onTap, label}) => {
  return (
    <div 
        onClick={() => onTap()}
        className={`border-2 border-main ${!isSelected ? '' : 'bg-main'} rounded-full w-9 h-9 flex items-center justify-center cursor-pointer ${isSelected ? "hover:opacity-80" : "hover:bg-gray-200"}`}>
        <p className={`${!isSelected ? 'text-black' : 'text-white'}`}>{label}</p>
    </div>
  )
}

export default PageButtonNo