import React from 'react';
import './radio_btn.css';

const RadioButton = ({ value, checked, onChange, label }) => (
    <label className="radio-button">
        <input
            type="radio"
            name="option"
            value={value}
            checked={checked}
            onChange={onChange}
            style={{ display: 'none' }}
        />
        <div className="radio-circle" style={{ borderColor: "var(--main-color)" }}>
            <div
                className="radio-circle-inner"
                style={{ backgroundColor: checked ? "var(--logo-main-color)" : "#ffffff" }}
            />
        </div>
        <span className="radio-label">{label}</span>
    </label>
);

export default RadioButton;
