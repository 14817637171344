import React from 'react';

const FloatingInput = ({ label, value, onChange }) => {
    const handleFocus = (event) => {
        document.activeElement.blur(); // Unfocus any currently focused element
        event.target.focus(); // Focus the input element
    };

    const handleLabelClick = (event) => {
        // Prevent default label behavior
        event.preventDefault();
        // Unfocus any currently focused element
        document.activeElement.blur();
        // Focus the input associated with the label
        document.getElementById(`id${value}`).focus();
    };

    return (
        <div>
            <div className="relative">
                <input
                    value={value}
                    onFocus={handleFocus}
                    onChange={onChange}
                    type="text"
                    id={`id${value}`}
                    className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-white border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 peer"
                    placeholder=" "
                />
                <label
                    htmlFor="floating_filled"
                    onClick={handleLabelClick}
                    className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:border-gray-300 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                    {label}
                </label>
            </div>
        </div>
    );
};

export default FloatingInput;
