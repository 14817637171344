import apiClient from "../api_client";

const getSports = async () => {
  try {
    const response = await apiClient.get('/sports');
    return response.data.data;
  } catch (error) {
    console.error('Error get Sports: ' , error);
    throw error;
  }
}

const getProfile = async () => {
  try {
    const response = await apiClient.get('/account');
    return response.data.data;
  } catch (error) {
    console.error('Error get profile: ' , error);
  }
}

const editProfile = async ({firstname, lastname, profname, gender, telephone, selectedSports, locations, yearsOfExperience, image, logo}) => {
  try {
    const formData = new FormData();

    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("profname", profname);
    formData.append("gender", gender);
    formData.append("telephone", telephone);
    locations.forEach((location) => {
      formData.append('locations[]', location)
    });
    selectedSports.forEach((sport) => {
      formData.append('sports[]', sport)
    });
    formData.append("years_of_experience", yearsOfExperience);
    if (typeof(image) !== 'string') {
        formData.append("image", image);
    }
    if (typeof(logo) !== 'string') {
        formData.append("logo", logo);
    }

    const response = await apiClient.post('/account', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {
      "success": true,
      "data": response.data.data,
    }
  } catch (error) {
    console.log(error);
    return {
      "success": false,
      "error": Array.isArray(error.response?.data?.error[0]) ?
          error.response?.data?.error[0][0] :
          error.response?.data?.error[0],
    }
  }
}

export const profileService = {
    getSports,
    getProfile,
    editProfile,
}