import dayjs from 'dayjs';
import { apiSlice } from './apiSlice';

export const sessionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSessions: builder.query({
      query: ({ startDate, endDate, page, limit }) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
        return `https://clickcoach.app/index.php?route=rest/coach/session&page=${page}&limit=${limit}&date_start=${formattedStartDate}&date_end=${formattedEndDate}`;
      },
      transformResponse: (response) => response.data,
      providesTags: (result, error, { startDate, endDate }) => [{ type: 'Sessions', startDate, endDate }],
    }),
    getSessionsByUser: builder.query({
      query: ({ traineeId, page, limit }) =>
        `https://clickcoach.app/index.php?route=rest/coach/sessions_by_trainee&trainee_id=${traineeId}&page=${page}&limit=${limit}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, { traineeId }) => [{ type: 'sessions_by_user', traineeId }],
    }),
    getDashboardSessions: builder.query({
      query: ({ startDate, endDate }) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
        return `https://clickcoach.app/index.php?route=rest/coach/getSessionsForDashboard&date_start=${formattedStartDate}&date_end=${formattedEndDate}`;
      },
      transformResponse: (response) => response.data,
    }),
    addSession: builder.mutation({
      query: (session) => ({
        url: `coach/session`,
        method: 'POST',
        body: session,
      }),
      invalidatesTags: (result, error, { traineeId }) => [
        { type: 'Payment' },
        { type: 'Sessions' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'sessions_by_user', traineeId },
      ],
    }),
    editSession: builder.mutation({
      query: (session) => {
        // Print session.date and session.time
         // Helper function to format date as "yyyy-mm-dd"
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };

        // Helper function to format time as "hh:mm"
        const formatTime = (dateString) => {
          const date = new Date(dateString);
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${hours}:${minutes}`;
        };

        // Format session.date and session.time
        const formattedDate = formatDate(session.date);
        const formattedTime = formatTime(session.time);

        session.date = formattedDate;
        session.time = formattedTime;

        return {
          url: `coach/session/${session.session_id}`,
          method: 'PUT',
          body: session,
        };
      },
      invalidatesTags: (result, error, { session }) => [
        { type: 'sessions_by_user', session },
        { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'Sessions' },
      ],    
    }),
    
    deleteSession: builder.mutation({
      query: (id) => ({
        url: `coach/session/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { traineeId }) => [
        { type: 'sessions_by_user', traineeId },
        { type: 'Payment' },
        { type: 'Incomes' },
        { type: 'Trainee', id: 'LIST' },
        { type: 'Sessions' },
      ],            
    }),

  }),
});

// Export hooks for the injected endpoints
export const { 
  useAddSessionMutation, 
  useGetSessionsByUserQuery, 
  useGetSessionsQuery, 
  useGetDashboardSessionsQuery,
  useEditSessionMutation,
  useDeleteSessionMutation, 
} = sessionApi;
