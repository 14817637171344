import { Link } from 'react-router-dom';

export default function PageTitle({ button, isActive, onClick }) {
  return (
    <Link
      to={button.route}
      className={`relative linkk text-center py-1 w-full inline-block z-10 text-gray-900 font-semibold leading-7 ${isActive ? 'active text-main' : ''}`}
      onClick={onClick}
    >
      {button.title}
    </Link>
  );
}
