import React, {useEffect, useState} from 'react';
import { MdDeleteOutline } from "react-icons/md";
import { IoAddSharp } from "react-icons/io5";
import NormalInput from '../inputs/normal_input/normal_input';

const Locations = ({locations, setLocations}) => {
     const [inputs, setInputs] = useState(
        locations.length > 0
            ? locations.map(location => ({ id: Date.now() + Math.random(), value: location }))
            : [{ id: Date.now(), value: '' }]
    );

    // Function to add a new input
    const addInput = () => {
        const newInput = { id: Date.now(), value: '' };
        setInputs([...inputs, newInput]);
        setLocations([...locations, '']); // Add an empty string to locations array
    };

    // Handler to remove a specific input
    const removeInput = (id) => {
        setInputs(inputs.filter(input => input.id !== id));
        setLocations(locations.filter((_, index) => inputs[index].id !== id));
    };

    // Handler to update the location value
    const updateLocation = (id, value) => {
        setLocations(locations.map((location, index) => 
            inputs[index].id === id ? value : location
        ));
    };

    return (
        <div>
            <div className='flex justify-between'>
                <p className='font-semibold text-md'>Locations you coach in</p>
                <div
                    className='w-6 h-6 flex cursor-pointer hover:opacity-70 active:opacity-50 items-center justify-center bg-main rounded-full'
                    onClick={addInput}
                >
                    <IoAddSharp color='white' size={20} />
                </div>
            </div>
            <div className='h-3'></div>
            {
                inputs.map((input, index) => (
                    <div key={input?.id} className='flex items-center space-x-2 my-3'>
                        <NormalInput 
                            placeholder="Enter a location you coach in. (Eg. Jamhour Swimming Pool)"
                            value={locations[index] || ''}
                            onChange={(e) => updateLocation(input.id, e.target.value)}
                        />
                        <MdDeleteOutline
                            size={25}
                            onClick={() => removeInput(input?.id)}
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                ))
            }
        </div>
    );
}

export default Locations