import apiClient from "../api_client";

const getStore = async () => {
    try {
        const response = await apiClient.get('/stores/0');
        console.log(response.data);
        
        return response.data.data;
    } catch (error) {
        console.error('Error fetching store:', error);
    }
}

export const appServices = {
    getStore
}