import React, {useRef} from 'react'
import { IoIosClose } from 'react-icons/io';

const UserLogo = ({logoSrc, setLogoSrc, setLogo}) => {
   const fileInputRef = useRef(null);

  const handleLogoClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setLogo(file);
        if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            setLogoSrc(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    };

  return (
    <>
       <div className='h-3'></div>
        <p className='font-semibold text-md'>Logo (Optional)</p>
        <div className='h-3' ></div>
        {
          !logoSrc ?
          <button onClick={handleLogoClick} className='border border-black rounded-md bg-white px-3 py-1'>
              Upload
          </button> : 
          <div className='relative h-36 w-36' >
            <img className='h-full w-full' src={logoSrc} alt="" />
            <div 
              onClick={() => { 
                setLogoSrc(null);
                setLogo(null);
              }}
              style={{top: "-5px", right: "-3px"}}
              className='absolute object-cover flex items-center cursor-pointer hover:opacity-80 active:opacity-60 justify-center h-5 w-5 bg-gray-300 rounded-full'
            >
              <IoIosClose size={30} />
            </div>
          </div>
        }
         <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            />
    </>
  )
}

export default UserLogo