
import React, { useEffect, useState } from "react";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import { IoTimeOutline } from "react-icons/io5";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";
import { FaRegHourglass } from "react-icons/fa6";
import { CircularProgress, Modal } from "@mui/material";
import EditPaymentModal from "./edit_payment";
import AddSessionModal from '../../components/sessions/add_session_modal';

const PaymentBox = ({payment}) => {
  const [page, setPage] = useState(1);
  const resetPaginationVars = () => {
    setPage(1);
  }
  const [isEditPaymentOpen, setIsEditPaymentOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);


  const handleRowClick = (payment) => {
    setSelectedPayment(payment);
      setIsEditPaymentOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPayment(null);
    setIsEditPaymentOpen(false);
  };

  return (
       <div className='max-w-96 w-full border gap-x-5  px-4 py-2 rounded-lg flex justify-between shadow-md' onClick={() => handleRowClick(payment)}>         
        <div className='flex flex-1 items-center gap-x-3'>
          <div className='min-w-12 min-h-12 rounded-full flex items-center justify-center bg-gray-100'>
              {
                payment.amount > 0 ?
                <FaArrowUpLong color='green' size={20} /> :
                <FaRegHourglass color='orange' size={20} />
              }
          </div>
          <div>
            <p className='font-semibold'>{payment?.trainee?.firstname} {payment?.trainee?.lastname}</p>
            <div className='flex gap-x-2 items-center'>
              <AiOutlineDollarCircle color='gray' size={20} />
              {
                payment.amount > 0 ?
              <p className='text-green-600 font-semibold text-md'>{payment.amount_formatted}</p> :
              <p className='text-orange-500 font-semibold text-md'>{payment.amount_formatted}</p> 
              }
            </div>
            {
              payment.note &&
              <p className='text-xs'>Note: ${payment.note}</p>
            } 
          </div>
        </div>
        <div className='flex justify-center flex-col items-start gap-y-1 py-4 text-gray-500 gap-x-1'>
          <div className='flex items-center justify-center'>
            <MdDateRange color='gray' size={20} />
            <p className='gray text-xs ml-1'>
                {payment.date_added.split(" ")[0]}
            </p>
          </div>
          <div className='flex items-center justify-center'>
            <IoTimeOutline color='gray' size={20} />
            <p className='gray text-xs ml-1'>
                {payment.date_added.split(" ")?.[1]}
            </p>
          </div>
         </div>
      <Modal
        open={isEditPaymentOpen}
        onClose={handleCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          
          <EditPaymentModal
            onClose={handleCloseModal} 
            payment={selectedPayment}
            resetPaginationVars={resetPaginationVars}
          />
        </div>
      </Modal>
      </div>
  )
}

export default PaymentBox