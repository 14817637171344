
import React, { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useDeleteExpensesMutation } from '../../redux/api/expenses';
import { useDispatch, useSelector } from 'react-redux';
import { getMessageFromError } from '../../utils/utils';
import { setHideEdit } from '../../redux/storeSlice';
const DeleteExpensesModal = ({ onClose, resetPaginationVars, expense_id }) => {
 
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const [deleteExpenses, { isLoading }] =
  useDeleteExpensesMutation();

  const handleDeleteExpenses = async () => {
    try {
      await deleteExpenses(expense_id).unwrap();
      dispatch(setHideEdit(true));
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  return (
    <div
  className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-20"
  onClick={onClose}
>
  <div
    className="relative mx-4 px-8 py-6 w-[350px] max-w-full overflow-y-auto bg-white rounded-2xl shadow-lg transform transition-all duration-300 ease-in-out"
    onClick={(e) => e.stopPropagation()}
  >
    <div className="flex justify-between items-center mb-4">
      <p className="font-bold text-xl text-gray-800">Delete Expenses</p>
      <IoIosClose
        size={30}
        className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
        onClick={() => onClose()}
      />
    </div>
    <h1 className="text-center text-xl font-semibold text-gray-700 mb-4">
      Are you sure?
    </h1>
    <div className="flex justify-end mt-5">
        <FormSubmitBtn
        text="Delete Expenses"
        isLoading={isLoading}
        onClick={() => {handleDeleteExpenses(); onClose()}} 
        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition-all duration-200 ease-in-out"
    />
    </div>
    {errMsg && (
      <p className="text-red-500 mt-3 text-center">{errMsg}</p>
    )}
  </div>
</div>

  );
};

export default DeleteExpensesModal;
