import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import IntroPage from './pages/intro_page';
import ProfilePage from './pages/profile_page';
import LoginPage from './pages/login_page';
import RegisterPage from './pages/register_page';
import WelcomePage from './pages/welcome_page';
import DashboardPage from './pages/dashboard_page';
import SessionsPage from './pages/sessions_page';
import TraineesPage from './pages/trainees_page';
import PaymentPage from './pages/payment_page';
import ProfitPage from './pages/profit_page';
import { appServices } from './services/app_services';
import { setStoreData } from './redux/storeSlice';
import { profileService } from './services/profile_services';
import { setUserData } from './redux/userSlice';
import LoadingPage from './pages/loading_page';
import Header from './components/common/header';
import TraineeAccountPage from './pages/trainee_account_page';

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

const MainApp = () => {
  const [loading, setLoading] = useState(true);
  const user = useSelector(state => state.user.data);
  const isAuthenticated = user != null;
  const isAccountComplete = user?.firstname?.length === 0 || user?.lastname?.length === 0;
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const store = await appServices.getStore();      
      dispatch(setStoreData(store));
      
      if (store?.customer != null) {
        const user = await profileService.getProfile();
        dispatch(setUserData(user));
      }
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  if (loading) {
    return <LoadingPage />;
  }

  // Define routes where header should appear
  const headerRoutes = ['/dashboard', '/sessions', '/trainees', '/payment', '/profit', '/profile', '/traineeaccount'];

  // Determine if the current route should display the header
  const shouldShowHeader = headerRoutes.includes(location.pathname);

  return (
    <div>
      {shouldShowHeader && <Header />}
      <Routes>
        <Route path="/" element={<Navigate to={isAuthenticated ? (isAccountComplete ? '/profile' : '/dashboard') : '/welcome'} />} />
        <Route path="/dashboard" element={isAuthenticated ? <DashboardPage /> : <Navigate to="/login" />} />
        <Route path="/sessions" element={isAuthenticated ? <SessionsPage /> : <Navigate to="/login" />} />
        <Route path="/trainees" element={isAuthenticated ? <TraineesPage /> : <Navigate to="/login" />} />
        <Route path="/payment" element={isAuthenticated ? <PaymentPage /> : <Navigate to="/login" />} />
        <Route path="/profit" element={isAuthenticated ? <ProfitPage /> : <Navigate to="/login" />} />
        <Route path="/login" element={!isAuthenticated ? <LoginPage /> : <Navigate to="/" />} />
        <Route path="/register" element={!isAuthenticated ? <RegisterPage /> : <Navigate to="/" />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/intro" element={<IntroPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/traineeaccount/:id" element={<TraineeAccountPage />} />
      </Routes>
    </div>
  );
};

export default App;
