import React, {useRef} from 'react'
import { IoIosAdd } from 'react-icons/io';
import defaultPic from '../../assets/profile_pic.webp';

const ProfilePic = ({imageSrc, setImageSrc, setImage}) => {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
          setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };    

  return (
     <div className='relative w-28 h-28 cursor-pointer border rounded-full' onClick={handleImageClick}>
        <img
            src={!imageSrc.length == 0 ? imageSrc : defaultPic}
            className='w-full h-full rounded-full object-cover'
            alt='Profile'
        />
            <div className='w-7 h-7 shadow absolute bottom-0 flex items-center justify-center right-0 bg-white rounded-full'>
            <IoIosAdd size={30} />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
    </div>
  )
}

export default ProfilePic