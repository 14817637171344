import React, { useEffect, useState } from 'react';
import { IoIosAdd } from "react-icons/io";
import {  CircularProgress, Modal } from '@mui/material';
import AddTraineeModal from '../components/trainees/add_trainee';
import { useGetTraineesQuery } from '../redux/api/traineeApi';
import { getForegroundColor } from '../utils/color_utils';
import LoadingPage from './loading_page';
import { PAGE_LIMIT } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

const TraineesPage = () => {
  const navigate = useNavigate();
  const [trainees, setTrainees] = useState([]);

  const [page, setPage] = useState(1);
  const { data: traineesResponse, error, isLoading } = useGetTraineesQuery({page, limit: PAGE_LIMIT});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [selectedTrainee, setSelectedTrainee] = useState(null);


  useEffect(() => {
    if (!traineesResponse) return;

    if (page == 1) {
      setTrainees(traineesResponse?.trainees)      
    } else {
      setTrainees((oldTrainees) => [...oldTrainees, ...traineesResponse?.trainees]);
    }
    setIsFetchingMore(false);
  }, [traineesResponse]);  

  const handleRowClick = (trainee) => {
    setSelectedTrainee(trainee);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTrainee(null);
  };

  if (isLoading) {
    return <LoadingPage />
  }

  return (
      <div className='flex items-center justify-center px-5 md:px-10'>
        <div className='flex w-full px-3 max-w-5xl'>
          <div className='w-full'>
            <div className='flex justify-between'>
              <p className='text-lg font-semibold'>Trainee Accounts</p>
              <div className='flex items-center justify-center bg-main text-white cursor-pointer hover:opacity-70 active:opacity-60 w-6 h-6 rounded-full'>
                <IoIosAdd size={25} onClick={() => handleRowClick(null)} />
              </div> 
            </div>
            <div className='h-2'></div>
            <p className='my-2'>Total: <span className='font-semibold'>{traineesResponse.total_trainees} trainees</span></p>
            <table className="w-full table-auto mt-4 rounded-md">
              <colgroup>
                  <col className='w-[10%]'/>
                  <col className='w-[25%]'/>
                  <col className='w-[25%]'/>
                  <col className='hidden md:table-cell w-[20%] '/>
                  <col className='w-[20%]'/>
              </colgroup>
              <thead>
                <tr className='border bg-gray-100'>
                  <th className='border'>Color</th>
                  <th className='border'>Name</th>
                  <th className='border'>Telephone</th>
                  <th className='border hidden md:table-cell'>Gender</th>
                  <th className='border'>Trainees</th>
                </tr>
              </thead>
              <tbody>
                {
                  trainees.length == 0 ?
                  <tr className='border'>
                    <td colSpan={5} className='text-sm text-center text-gray-600'>
                      You have not logged any trainees yet. 
                      <p 
                        className='text-logoMain underline font-semibold cursor-pointer text-sm'
                        onClick={() => handleRowClick(null)}
                      >
                        Start Logging
                      </p>
                    </td>

                  </tr> : 
                  trainees?.map((trainee, index) => {
                    return (
                       <tr 
                          key={index}
                          className='border hover:bg-gray-100 cursor-pointer' 
                          onClick={() => {  
                            navigate(`/traineeaccount/${trainee.customer_id}`, {state: trainee});
                          }}
                        >
                          <td>
                            <div className='flex items-center justify-center'>
                              <div 
                                style={{backgroundColor: trainee.color, color: getForegroundColor(trainee.color)}}
                                className='w-12 h-12 rounded-full flex justify-center items-center font-bold'>
                                {trainee.firstname[0]}{trainee.lastname[0]}
                              </div>
                            </div>
                          </td>
                          <td className='border'>{trainee.firstname} {trainee.lastname}</td>
                          <td className='border'>{trainee.telephone}</td>
                          <td className='border hidden md:table-cell'>{trainee.gender}</td>
                          <td className='border'>{trainee.subtrainees.map((subtrainee, index) => <p key={index}>{subtrainee.name}</p>)}</td>
                        </tr> 
                    )
                  })
                }
              </tbody>
            </table>
            {
              traineesResponse.current_page < traineesResponse.pages && 
              (
                <div className='flex justify-center mt-6'>
                 <button 
                    className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                    onClick={() => {
                      setPage((page) => page + 1);
                      setIsFetchingMore(true);
                    }}
                  >
                    {
                      isFetchingMore ?
                      <CircularProgress size={23} sx={{ color: 'white' }} />
                      : 
                      'Load More'
                    }
                  </button>
                </div>
              )
            }
            <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              trainee={selectedTrainee}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <div>
                <AddTraineeModal 
                    trainee={selectedTrainee}
                    onClose={handleCloseModal} 
                  />
              </div>
            </Modal>
          </div>
        </div>
      </div>  
  )
}

export default TraineesPage