import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { localStorageKeys } from '../../utils/local_storage_keys';
import { BASE_URL } from '../../utils/constants';

// Create the base query with `fetchBaseQuery` and add `prepareHeaders`
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(localStorageKeys.tokenKey);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Accept', 'application/json');
    return headers;
  },
});

// Define your API slice
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery, 
  endpoints: () => ({}), 
});

