export const getForegroundColor = (hex) => {
    // Remove the hash at the start if it's there
    if (hex == null) {
        return '#FFFFFF';
    }
    hex = hex.replace('#', '');

    // Parse the three hex components (r, g, b)
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Calculate the luminance using the sRGB formula
    // Reference: https://www.w3.org/TR/AERT/#color-contrast
    let luminance = 0.299 * r + 0.587 * g + 0.114 * b;

    // If the luminance is greater than a threshold (128), return black, else return white
    return luminance > 128 ? '#000000' : '#FFFFFF';
}
