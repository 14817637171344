import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosAdd, IoIosClose } from 'react-icons/io';
import { setShowAddPaymentModal } from '../../redux/storeSlice';
import { useGetTraineesQuery } from '../../redux/api/traineeApi';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, Checkbox, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { useAddSessionMutation } from '../../redux/api/sessionApi';
import { useEditSessionMutation } from '../../redux/api/sessionApi';
import { useDeleteSessionMutation } from '../../redux/api/sessionApi';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import ErrorPopup from '../common/error_popup';
import { getMessageFromError } from '../../utils/utils';
import dayjs from 'dayjs';
import LoadingPage from '../../pages/loading_page';
import { setTrainee } from '../../redux/storeSlice';
const AddSessionModal = ({ session, onClose}) => {
  const dispatch = useDispatch();

  const { data: traineeResponse, isLoading: isTraineesLoading } = useGetTraineesQuery();
  const [trainees, setTrainees] = useState([]);
  const store = useSelector(state => state.store.data); 
  const user = useSelector(state => state.user.data); 

  const [selectedTrainee, setSelectedTrainee] = useState(session?.trainee?.trainee_id ?? '');
  const [selectedTraineeFull, setSelectedTraineeFull] = useState(null); 
  const [selectedSubtrainees, setSelectedSubtrainees] = useState(session?.subtrainees?.map(tr => tr.subtrainee_id) ?? []);
  
  const [date, setDate] = useState(session?.date ? dayjs(session.date) : dayjs());
  const [time, setTime] = useState(session?.time ? dayjs(session.time, 'HH:mm:ss') : dayjs());
  const [note, setNote] = useState(session?.note ?? '');
  const [selectedSport, setSelectedSport] = useState(session?.sport_id ?? '');
  const [status, setStatus] = useState(session?.session_status_id ?? '');
  const [price, setPrice] = useState(session?.price ?? 0);
  const [location, setLocation] = useState(session?.location ?? '');

  const [addSession, { isLoading:  isAddLoading }] = useAddSessionMutation();
  const [editSession, { isLoading: isEditLoading }] = useEditSessionMutation();
  const [deleteSession, { isLoading:DeleteSession }] = useDeleteSessionMutation();

  const [errMsg, setErrMsg] = useState('');

  const [isAddingNewSubtrainee, setIsAddingNewSubtrainee] = useState(false);
  const [newSubtraineeName, setNewSubtraineeName] = useState('');
  const [newSubtraineeAddToAccount, setNewSubtraineeAddToAccount] = useState(true);

  useEffect(() => {
    console.log(`date is ${date}`);
    console.log(`time is ${time}`);
  }, [date, time]);
 

  useEffect(() => {
    setTrainees(traineeResponse?.trainees);
  }, [traineeResponse]);


  const handleAddSession = async () => {
    try {
      if (session) {
        await editSession({
          session_id:session.session_id,
          sport_id: selectedSport,
          note,
          date,
          time,
          location,
          price,
          subtrainees: selectedSubtrainees,
          new_trainee: newSubtraineeName,
          add_to_account: newSubtraineeAddToAccount
        }).unwrap();

      } else {
          await addSession({
            trainee_id: selectedTrainee,
            date,
            time,
            sport_id: selectedSport,
            note,
            location,
            price,
            subtrainees: selectedSubtrainees,
            new_trainee: newSubtraineeName,
            add_to_account: newSubtraineeAddToAccount
          }).unwrap();
      }
      onClose();
      if(!session){
        dispatch(setShowAddPaymentModal(true));
        dispatch(setTrainee(selectedTraineeFull));
      }
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleDeleteSession = async () => {
    try {
      await deleteSession(session.session_id).unwrap();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleTraineeChange = (e) => {
    setSelectedTrainee(e.target.value);
    const trainee = trainees.find(t => t.customer_id === e.target.value);
    setSelectedTraineeFull(trainee); 
    setSelectedSubtrainees([]);
  };

  const handleAddNewTraineeClick = () => {
    setIsAddingNewSubtrainee(true);
  };

  const handleCancelNewSubtrainee = () => {
    setIsAddingNewSubtrainee(false);
    setNewSubtraineeName('');
    setNewSubtraineeAddToAccount(true);
  };

  if (isTraineesLoading) {
    return <LoadingPage />
  }

  // Find the current subtrainees list based on the selected trainee
  const currentSubtrainees = trainees?.find(trainee => trainee.customer_id === selectedTrainee)?.subtrainees || [];

  // Function to map IDs to names
  const getSubtraineeName = (id) => {
    const subtrainee = currentSubtrainees?.find(sub => sub.subtrainee_id === id);
    return subtrainee ? subtrainee.name : '';
  };

  // Function to get the names of the selected subtrainees
  const getSelectedNames = () => {
    return selectedSubtrainees.map(id => getSubtraineeName(id)).join(', ');
  };
  const isLoading = session? isEditLoading : isAddLoading;
  return (
    <div 
        className='fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-50'
        onClick={onClose}
    >
        <div
            className="relative  px-7 py-6 w-[500px]  h-full overflow-y-auto bg-white rounded-lg  overflow-hidden" 
            onClick={(e) => e.stopPropagation()}
        >
            <div className='flex justify-between'>
                <p className='font-bold text-lg'>
                    {
                        !session ? "Add New Session" : "Session Details"
                    }
                </p>
                <IoIosClose size={30} className="text-gray-500 cursor-pointer" onClick={() => onClose()} />
            </div>

            <div className='pt-6 flex flex-col gap-y-3'>
                <article>
                    <label className='font-semibold' >Trainee Account</label>
                    <div className='h-2'></div>
                    <Select 
                        fullWidth
                        value={selectedTrainee}
                        onChange={handleTraineeChange}
                    >
                        {trainees?.map((trainee, index) => (
                            <MenuItem key={index} value={trainee.customer_id}>
                                {trainee.firstname} {trainee.lastname}
                            </MenuItem>
                        ))}
                    </Select>
                    { selectedTraineeFull != null && (
                      <p className="mt-2 text-sm">
                          Trainee Balance: {selectedTraineeFull?.balance}
                      </p>
                    )
                    }
                </article>
                
                <article>
                    <label className='font-semibold' >Trainees</label>
                    <div className='h-2'></div>
                    <Select 
                        fullWidth
                        multiple
                        value={selectedSubtrainees}
                        renderValue={getSelectedNames}
                        onChange={(e) => setSelectedSubtrainees(e.target.value)}
                    >
                        {trainees?.find(trainee => trainee.customer_id == selectedTrainee)?.subtrainees.map(
                            (subtrainee) => (
                                <MenuItem key={subtrainee.subtrainee_id} value={subtrainee.subtrainee_id}>
                                    <Checkbox checked={selectedSubtrainees.includes(subtrainee.subtrainee_id)} />
                                    <ListItemText primary={subtrainee.name} />
                                </MenuItem>
                            )
                        )}
                    </Select>
                </article>

                {
                    !isAddingNewSubtrainee && 
                     <div className='flex cursor-pointer hover:opacity-60 active:opacity-45' onClick={handleAddNewTraineeClick}>
                        <IoIosAdd size={25} />
                        <p>Add new Trainee</p>
                    </div>
                }

                {/* New Subtrainee Fields */}
                {isAddingNewSubtrainee && (
                  <div className='border p-3 rounded mt-3'>
                    <article>
                      <label className='font-semibold'>New Subtrainee Name</label>
                      <div className='h-2'></div>
                      <TextField 
                        value={newSubtraineeName}
                        onChange={(e) => setNewSubtraineeName(e.target.value)}
                        variant="outlined" 
                        fullWidth
                      />
                    </article>

                    <article className='mt-3'>
                      <label className='font-semibold'>Action</label>
                      <div className='h-2'></div>
                      <Select 
                        fullWidth
                        value={newSubtraineeAddToAccount}
                        onChange={(e) => setNewSubtraineeAddToAccount(e.target.value)} 
                      >
                        <MenuItem value={false}>Add Trainee to Session Only</MenuItem>
                        <MenuItem value={true}>Add Trainee to Account</MenuItem>
                      </Select>
                    </article>

                    <div className='flex justify-end mt-3'>
                      <button 
                        className='text-red-500 hover:underline'
                        onClick={handleCancelNewSubtrainee}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                <article>
                    <label className='font-semibold'>Sport</label>
                    <div className='h-2'></div>
                    <Select 
                        fullWidth
                        value={selectedSport}
                        onChange={(e) => setSelectedSport(e.target.value)} 
                    >
                        {user.sports.map((sportObj) => (
                            <MenuItem key={sportObj.sport_id} value={sportObj.sport_id}>
                                {sportObj.name}
                            </MenuItem>
                        ))}
                    </Select>
                </article>

            <ErrorPopup
                message={errMsg}
                isVisible={errMsg?.length > 0}
                onClose={() => setErrMsg("")}
              />
              
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className='flex gap-x-3'>
                        <article>
                            <label className='font-semibold'>Date</label>
                            <div className='h-2'></div>
                            <div>
                                <DatePicker
                                    label=""
                                    value={date}
                                    onChange={(newValue) => setDate(newValue)}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </div>
                        </article>
                        <div className='h-3'></div>
                        <article>
                            <label className='font-semibold'>Time</label>
                            <div className='h-2'></div>
                            <TimePicker 
                                label="" 
                                value={time}
                                onChange={(newValue) => setTime(newValue)}
                                slotProps={{ textField: { fullWidth: true } }}
                            />
                        </article>
                    </div>
                </LocalizationProvider>

                <article>
                    <label className='font-semibold'>Price</label>
                    <div className='h-2'></div>
                    <TextField 
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        type="number"
                        id="outlined-basic" 
                        variant="outlined" 
                        sx={{width: "100%"}} 
                    />
                </article>

                <article>
                    <label className='font-semibold'>Location</label>
                    <div className='h-2'></div>
                    <Autocomplete
                        value={location}
                        onChange={(e, newValue) => { 
                            setLocation(newValue);
                        }}
                        freeSolo={true}
                        id="combo-box-demo"
                        options={user.locations ?? []}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} label="" />}
                    />
                </article>

                <article>
                    <label className='font-semibold'>Note</label>
                    <div className='h-2'></div>
                    <TextField 
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        variant="outlined" 
                        placeholder='Write anything here...'
                        sx={{width: "100%"}} 
                    />
                </article>
                <div className="flex justify-end items-center mt-6">
          <FormSubmitBtn
            text={session ? "Edit" : "Add"}  
            isLoading={isAddLoading || isEditLoading} // Handle both add/edit loading
            onClick={() => handleAddSession()}
          />   
           {session && (
         <FormSubmitBtn
         text="Delete"
         isLoading={isLoading}
         onClick={() => {handleDeleteSession(); onClose()}} 
         className="bg-red-500 hover:bg-red-600 text-white rounded-lg transition-all duration-200 ease-in-out ml-5"
     />
        )}
        </div>
            </div>

        </div>
    </div>
  );
};

export default AddSessionModal;
