import dayjs from 'dayjs';
import { apiSlice } from './apiSlice';

export const sessionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIncomes: builder.query({
      query: ({ startDate, endDate, page , limit}) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
        return `https://clickcoach.app/index.php?route=rest/coach/income&limit=5&page=${page}&limit=${limit}&date_start=${formattedStartDate}&date_end=${formattedEndDate}`;
      },
      transformResponse: (response) => response.data,
      providesTags: (result, error) => [{ type: 'Incomes'}],
    }),
  }),
});

export const { useGetIncomesQuery } = sessionApi;
