import { CircularProgress } from '@mui/material';
import React, { useState } from 'react'

const WideButton = ({label, onClick}) => {
  const [loading, setLoading] = useState(false);
  const clicked = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return (
  <div 
      onClick={() => clicked()}
      className='w-full bg-main hover:opacity-70 active:opacity-60 flex justify-center items-center py-2 rounded-xl cursor-pointer'
  >
    {
      loading ? 
      <CircularProgress size={24} sx={{color: "white"}} />
      :
      <p className='text-white text-center'>{label}</p>
    }
  </div>
)

}

export default WideButton