import React, { useEffect, useState } from 'react'
import FloatingInput from '../components/inputs/floating_input/floating_input'
import CustomRadioButton from '../components/inputs/radio/radio_btn';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input'
import PageButtonNo from '../components/buttons/page_no_button/page_no_button';
import CustomCheckBox from '../components/inputs/checkbox/checkbox';
import NormalInput from '../components/inputs/normal_input/normal_input';
import WideButton from '../components/buttons/wide_button/wide_button';
import ProfilePic from '../components/profile/profile_pic';
import UserLogo from '../components/profile/user_logo';
import Locations from '../components/profile/locations';
import { profileService } from '../services/profile_services';
import { profile1ValidationSchema } from '../utils/form-validations/profile_validation_schema';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {setUserData} from '../redux/userSlice';

const ProfilePage = () => {
    const user = useSelector((state => state.user.data));
    
    const [sports, setSports] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profName, setProfName] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
    const [yearsOfExp, setYearsOfExp] = useState('');
    const [locations, setLocations] = useState(['']);
    const [logoSrc, setLogoSrc] = useState(''); // base 64
    const [logo, setLogo] = useState('');
    const [selectedSports, setSelectedSports] = useState([]);
    const [image, setImage] = useState('');
    // hold base 64
    const [imageSrc, setImageSrc] = useState('');

    const [page, setPage] = useState(1);
    const [err, setErr] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validatePage1 = async (event) => {
        event.preventDefault();
        const data = {
            firstName: event.target[0].value,
            lastName: event.target[1].value,
            phoneNumber: event.target[2].value,
        }
        const isValid = profile1ValidationSchema.isValid(data);
    }

    const handleCheckboxChange = (sportId, isChecked) => {
        setSelectedSports((prevSelectedSports) => {
            if (isChecked) {            
                return [...prevSelectedSports, sportId];
            } else {
                return prevSelectedSports.filter(id => id !== sportId);
            }
        });
    };

    const submit = async () =>  {
       setErr('');

       const response =  await profileService.editProfile({
            firstname: firstName,
            lastname: lastName,
            profname: profName,
            gender: gender,
            telephone: phone,
            selectedSports: selectedSports,
            locations: locations,
            yearsOfExperience: yearsOfExp,
            image: image, // not imageSrc, because want to send a file not base64
            logo: logo,
        });
        if (response.success) {
            dispatch(setUserData(response.data));
            navigate('/');
        } else {
            setErr(response.error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const newSports = await profileService.getSports();
            setSports(newSports);
        }
        fetchData();
    }, []);

    // Update state when user data changes
    useEffect(() => {
        if (user) {
            console.log(user);
            
            setFirstName(user.firstname || '');
            setLastName(user.lastname || '');
            setProfName(user.profname || '');
            setPhone(user.telephone || '');
            setGender(user.gender || '');
            setLocations(user.locations || []);
            setYearsOfExp(user.years_of_experience || '');
            setLogoSrc(user.logo || '');
            setSelectedSports(user.sports.map(sport => sport.sport_id) || []);
            console.log('selected sports: ',user.sports.map(sport => sport.sport_id));
            
            setImageSrc(user.image || '');            
        }
    }, [user]);

    return (
        <div className='flex flex-col justify-center items-center'>
            <div className='flex flex-col items-center  w-full max-w-[800px] bg-lightGray h-full md:h-auto md:shadow-md'>
                <header className='bg-main rounded-b-2xl w-full flex flex-col justify-center items-center'>
                    <div className='h-2'></div>
                    <p className='text-white font-semibold text-2xl '>Profile</p>
                    <div className='h-2'></div>
                    <p className='text-white text-sm px-3 text-center' >Your information will help us simplify your coaching journey</p>
                    <div className='h-4'></div>
                    <ProfilePic 
                        imageSrc={imageSrc} 
                        setImageSrc={setImageSrc} 
                        setImage={setImage}
                    />
                    <div className='h-4'></div>
                </header>
                <section className='w-4/5'>
                        <div className='h-4' ></div>
                    <div className='flex justify-center'>
                       <PageButtonNo isSelected={page == 1} label="1" onTap={() => {
                           setPage(1);
                       }} />
                        <div className='w-2' ></div>
                       <PageButtonNo isSelected={page == 2} label="2" onTap={() => { 
                           setPage(2);
                        }} />
                    </div>

                    {
                        page == 1 ?

                        <form onSubmit={validatePage1}>
                            <div className='h-4' ></div>
                            <p className='font-semibold text-md'>Personal information</p>
                            <div className='h-4' ></div>
                            <FloatingInput value={firstName} onChange={(e) => setFirstName(e.target.value)} label="First Name" />
                            <div className='h-4' ></div>
                            <FloatingInput value={lastName} onChange={(e) => setLastName(e.target.value)} label="Last Name" />
                            <div className='h-4'></div>
                            <FloatingInput value={profName} onChange={(e) => setProfName(e.target.value)} label="Professional Name" />
                            <div className='h-4'></div>
                        
                            <PhoneInput
                                className="phone-input-custom"
                                placeholder="Enter phone number"
                                defaultCountry="LB"
                                value={phone}
                                onChange={setPhone} 
                            />
                            <div className='h-5' ></div>

                            <div className='h-4' ></div>
                            <p className='font-semibold text-md'>Gender</p>
                            <div className='h-3'></div>
                        
                            <div className='flex justify-between'>
                                <CustomRadioButton
                                    value='male'
                                    label="Male"
                                    checked={gender == 'male'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                                <CustomRadioButton
                                    value='female'
                                    label="Female"
                                    checked={gender == 'female'}
                                    onChange={(e) => setGender(e.target.value)}
                                />
                              
                            </div>
                            <div className='h-5'></div>
                            <WideButton label="Continue" onClick={() => setPage(2)} />
                            <div className='h-5'></div>
                        </form> 
                        : 
                        <>
                            <div>
                                <div className='h-4' ></div>
                                <p className='font-semibold text-md'>Sports you teach</p>
                                <div className='h-4'></div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4'>
                                    {                          
                                        sports.map((sport) => {   
                                            return (
                                                <CustomCheckBox 
                                                    key={sport.sport_id} 
                                                    isSelected={selectedSports.includes(sport.sport_id)} 
                                                    sport={sport} 
                                                    onChange={handleCheckboxChange} 
                                                />
                                            )
                                    }
                                )
                                }
                                </div>
                                <div className='h-4'></div>
                            </div>
                            <div>
                                <div className='h-4' ></div>
                                <p className='font-semibold text-md'>Years of Experience</p>
                                <div className='h-3'></div>
                                <NormalInput 
                                    value={yearsOfExp}    
                                    onChange={(e) => setYearsOfExp(e.target.value)}
                                    placeholder="Enter your years of experience. (Eg. 3)" 
                                    digitsOnly={true} 
                                />
                            </div>
                            <section>
                                <div className='h-6' ></div>
                                <Locations locations={locations} setLocations={setLocations} />
                                <div className='h-8'></div>
                                <UserLogo logoSrc={logoSrc} setLogoSrc={setLogoSrc} setLogo={setLogo} />
                                <div className='h-8'></div>
                                <WideButton label="Submit" onClick={async () => await submit()}/>
                                {err && <p className='text-red-500 my-3 text-center'>{err}</p>}
                            </section>
                            <div className='h-8'></div>
                        </>
                    }
                </section>
            </div>
        </div>
    )
}

export default ProfilePage