import { useState, useEffect, useRef } from 'react';
import { FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { Dialog, DialogPanel, PopoverButton, PopoverPanel, Popover } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import PageTitle from './page_title';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { authService } from '../../services/auth_services';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../../redux/userSlice';
import profilePic from '../../assets/profile_pic.webp';
import './header.css';

const buttons = [
  { title: 'Dashboard', route: '/dashboard' },
  { title: 'Sessions', route: '/sessions' },
  { title: 'My Trainees', route: '/trainees' },
  { title: 'Payments', route: '/payment' },
  { title: 'Profit', route: '/profit' },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeRoute, setActiveRoute] = useState('/');
  const user = useSelector(state => state.user.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Update active route based on current path
  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  const logout = async () => {
    await authService.logout();
    dispatch(logOutUser());
    navigate('/');
  };

  return (
    <header>
      <nav aria-label="Global" className="mx-auto w-full flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="hidden lg:flex lg:flex-1">
          <img className='w-12 h-12 rounded-md' src="/logo.png" alt="" />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
         <div className={`hidden lg:flex navbarr max-h-80`}>
          {buttons.map((btn, index) => (
            <PageTitle
              key={index}
              button={btn}
              isActive={btn.route === activeRoute}
              onClick={() => setActiveRoute(btn.route)}
            />
          ))}
          <div className='animation start-home'></div>
        </div>
        <div className="lg:flex lg:flex-1 lg:justify-end">
          <Popover className="relative">
            <PopoverButton className="focus:outline-none">
              <img
                className='w-12 h-12 rounded-full cursor-pointer'
                src={user?.image ? user.image : profilePic}
                alt=""
              />
            </PopoverButton>
            <PopoverPanel anchor="bottom" className="rounded-md mt-1 flex flex-col bg-gray-100">
              <Link to='/profile' className='hover:bg-gray-300 px-3 py-2'>
                <div className='flex items-center'>
                  <FaUser />
                  <div className='w-2'></div>
                  <p>Profile</p>
                </div>
              </Link>
              <div className='bg-gray-300' style={{height: "1px"}}></div>
              <Link className='hover:bg-gray-300 px-3 py-2'>
                <div className='flex items-center' onClick={() => logout()}>
                  <FiLogOut color='#ff0000' size={18} />
                  <div className='w-2'></div>
                  <p className='text-red-500'>Log Out</p>
                </div>
              </Link>
            </PopoverPanel>
          </Popover>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <img className='w-12 h-12 rounded-sm' src="/logo.png" alt="" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {buttons.map((btn, index) => (
                  <Link
                    key={index}
                    to={btn.route}
                    className={`${btn.route !== activeRoute ? '' : 'bg-gray-200'} mr-3 w-full block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                    onClick={() => {
                      setActiveRoute(btn.route)
                      setMobileMenuOpen(false);
                    }}
                  >
                    {btn.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
