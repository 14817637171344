import React, { useState } from 'react'
import NormalInput from '../components/inputs/normal_input/normal_input'
import WideButton from '../components/buttons/wide_button/wide_button'
import { useNavigate } from 'react-router-dom'
import { authService } from '../services/auth_services'
import { getMessageFromError } from '../utils/utils'
import { useDispatch } from 'react-redux'
import { setUserData } from '../redux/userSlice';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    if (email.length  < 5) {
      return setError('Email is too short');
    }
    if (password.length  < 4) {
      return setError('Password is too short');
    }
   
    try {
      const user = await authService.signIn(email, password);
      console.log('user ', user);
      
      dispatch(setUserData(user));
      setError('');
      navigate('/');
    } catch (error) {    
      setError(getMessageFromError(error));
    }
  };

  return (
    <div className='h-screen w-screen flex items-center justify-center'>
        <div className='md:shadow-lg md:bg-lightGray w-[500px] flex flex-col px-10'>
            <div className='h-8'></div>
            <p className='text-2xl font-semibold text-center'>Sign in</p>
            <div className='h-5'></div>
            <div>
                <label htmlFor="">Email</label>
                <div className='h-2'></div>
                <NormalInput 
                    value={email}
                    placeholder="Enter your email" 
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className='h-7'></div>
            <div>
                <label htmlFor="">Password</label>
                <div className='h-2'></div>
                <NormalInput 
                    value={password}
                    placeholder="Enter your password" 
                    onChange={(e) => setPassword(e.target.value)}
                    isPassword={true}
                />
            </div>
            <div className='h-8'></div>
            <WideButton label="Sign in" onClick={async () => await handleLogin() }  />
            { error && <p className='text-red-500 mt-3 text-sm text-center'>{error}</p>}
            <div className='h-5'></div>
            <div className='flex justify-center cursor-pointer' onClick={() => navigate('/register')} >
                <p className='text-sm text-gray-600'>Don't have an account? </p>
                <div className='w-1'></div>
                <p className='text-sm font-semibold'>Register</p>
            </div>
            <div className='h-4'></div>

        </div>
    </div>
  )
}

export default LoginPage