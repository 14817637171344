import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  showAddPaymentModal: false,
  showAdd: false,
  trainee: null,
  hideEdit:false,
  ExpenseId:[]
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreData: (state, action) => {
      state.data = action.payload;
    },
    setShowAddPaymentModal: (state, action) => {
      state.showAddPaymentModal = action.payload;
    },
    setShowAdd: (state, action) => {
      state.showAdd = action.payload;
    },
    setTrainee: (state, action) => {
      state.trainee = action.payload;
    },
    setHideEdit: (state, action) => {
      state.hideEdit = action.payload;
    },
    setExpenseId: (state, action) => {
      state.ExpenseId = action.payload;
    },
  },
});

// Export actions to be used in components
export const { setStoreData, setShowAddPaymentModal,setShowAdd ,setTrainee,setHideEdit,setExpenseId} = storeSlice.actions;

// Export the reducer to be included in the store
export default storeSlice.reducer;
