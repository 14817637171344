import * as Yup from 'yup';

export const profile1ValidationSchema = Yup.object({
  firstName: Yup.string()
    .min(3, 'First Name must be at least 3 characters')
    .required('First Name is required'),
  lastName: Yup.string()
    .min(3, 'Last Name must be at least 3 characters')
    .required('Last Name is required'),
  phoneNumber: Yup.string()
    .min(4, 'Phone is invalid')
    .required('Phone Number is required'),
});