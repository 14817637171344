import React, { useState ,useRef} from 'react';
import { IoIosClose } from 'react-icons/io';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {useEditTraineeMutation } from '../../redux/api/traineeApi';
import { getMessageFromError } from '../../utils/utils';
import SubTraineesBox from "../trainees/subtrainees_box";
import PhoneInput from "react-phone-number-input";


const EditTraineeModal = ({ onClose ,trainee}) => {

  const [errMsg, setErrMsg] = useState('');
  const colorRef = useRef();
  const [editTrainee, { isLoading: isEditLoading }] = useEditTraineeMutation();
  const { id } = useParams();
  const [firstName, setFirstName] = useState(trainee?.firstname ?? "");
  const [lastName, setLastName] = useState(trainee?.lastname ?? "");
  const [phone, setPhone] = useState(trainee?.telephone ?? "");
  const [gender, setGender] = useState(trainee?.gender ?? "");
  const [color, setColor] = useState(trainee?.color ?? "#ff0000");
  const [subtrainees, setSubtrainees] = useState(
    trainee?.subtrainees?.map((subtrainee) => subtrainee.name) ?? [""]
  );

  const handleEditTrainee = async () => {
    try {
      await editTrainee({
        id: id,
        firstname: firstName,
        lastname: lastName,
        telephone: phone,
        gender: gender,
        trainees: subtrainees,
        color: color,
      }).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };


  return (
    <div
    className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
    onClick={onClose}
  >
    <div
      className="relative mx-4 px-8 py-6 w-[400px] max-w-full overflow-y-auto bg-white rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
      onClick={(e) => e.stopPropagation()}
    >

      <div className="flex justify-between items-center mb-6 pb-2 border-b border-gray-300">
        <h2 className="font-bold text-2xl text-gray-900">Edit Trainee</h2>
        <IoIosClose
          size={35}
          className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
          onClick={onClose}
        />
      </div>
  
      <section>
  <p className="font-semibold text-lg mb-3">Personal Information</p>
  <div className="flex items-center justify-start mb-4">
    <label className="text-gray-700 font-semibold w-1/3">First Name</label>
    <input
      type="text"
      className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
      value={firstName}
      onChange={(e) => setFirstName(e.target.value)}
      placeholder="Enter first name"
    />
  </div>

  <div className="flex items-center justify-start mb-4">
    <label className="text-gray-700 font-semibold w-1/3">Last Name</label>
    <input
      type="text"
      className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      placeholder="Enter last name"
    />
  </div>
                <div className="flex items-center justify-start mb-4">
  <label className="text-gray-700 font-semibold w-1/3" htmlFor="gender">Gender</label>
  <select
    id="gender"
    className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
    value={gender}
    onChange={(e) => setGender(e.target.value)}
  >
    <option value="" disabled>Select gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
  </select>
</div>


  <div className="flex items-center justify-start mb-4">
    <label className="text-gray-700 font-semibold w-1/3">Phone Number</label>
    <PhoneInput
      className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
      placeholder="Phone number"
      defaultCountry="LB"
      value={phone}
      onChange={setPhone}
    />
  </div>

  <div className="flex items-center justify-start mb-4">
    <label className="text-gray-700 font-semibold w-1/3">Color</label>
    <div className="w-12 h-12 rounded-full cursor-pointer border-2"
      style={{ backgroundColor: color}}
      onClick={() => colorRef.current?.click()}
    ></div>
    <input
      ref={colorRef}
      type="color"
      value={color}
      onChange={(e) => setColor(e.target.value)}
      className="hidden"
    />
  </div>
</section>
<section className="my-7">
            <SubTraineesBox
              subtrainees={subtrainees}
              setSubtrainees={setSubtrainees}
            />
          </section>
  

      <div className="flex justify-end items-center mt-6">
        <FormSubmitBtn
          text="Edit"
          isLoading={isEditLoading}
          onClick={handleEditTrainee}
        />
      </div>
      {errMsg && (
        <p className="text-red-500 mt-4 text-center font-semibold">{errMsg}</p>
      )}
    </div>
  </div>
  
  );
};

export default EditTraineeModal;
