import './checkbox.css';

const CustomCheckBox = ({ isSelected, sport, onChange }) => {  
  const handleChange = (event) => {
    onChange(sport.sport_id, event.target.checked);
  };

  return (
    <div className='flex mr-1'>
      <div className="cntr">
        <input 
          type="checkbox" 
          id={`cbx-${sport.sport_id}`} 
          className="c-cbx hidden-xs-up" 
          checked={isSelected} 
          onChange={handleChange} 
        />
        <label htmlFor={`cbx-${sport.sport_id}`} className="cbx"></label>
      </div>
      <div className='w-2'></div>
      <div className='flex items-center justify-center'>
        <label htmlFor={`cbx-${sport.sport_id}`}>{sport.name}</label>
      </div>
    </div>
  );
};

export default CustomCheckBox;