import React, { useEffect } from 'react';
import { getForegroundColor } from "../../utils/color_utils";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const SessionBox = ({session}) => {

    const store = useSelector(state => state.store.data);

    const paidStatusId = store.session_statuses.find(session => session.name.toLowerCase() == 'paid').session_status_id
    const pendingStatusId = store.session_statuses.find(session => session.name.toLowerCase() == 'pending').session_status_id
    
    return (
        <div className='flex mt-3'>
            <div className=' w-full max-w-80'>
                <p className='font-semibold'>{dayjs(session?.date).format("dddd, D MMMM, YYYY")} -  {session.time_formatted}</p>
                <div 
                    className='h-20 w-full md:w-96 gap-x-3 bg-slate-100 px-3 mt-2 cursor-pointer rounded-2xl border flex items-center justify-between'
                >
                    <main className='flex gap-x-3'>
                        <div  
                            className='w-12 h-12 rounded-full flex items-center justify-center'
                            style={{backgroundColor: session?.trainee?.color, color: getForegroundColor(session?.trainee?.color)}}
                        >
                            <p>{session?.trainee?.firstname?.charAt(0).toUpperCase()}{session?.trainee?.lastname?.charAt(0).toUpperCase()}</p>
                        </div>
                        <div>
                            <p>{session?.trainee?.firstname} {session?.trainee?.lastname}</p>
                            <p className='text-sm font-semibold'>{session?.price_formatted}</p>
                        </div>
                    </main>
                    {
                        session.session_status_id == paidStatusId ?
                         <aside className='px-3 py-1 rounded-md text-sm' style={{ color: "#099909", backgroundColor: "#e1ebe1" }} >
                            Paid
                        </aside> 
                        : 
                        session.session_status_id == pendingStatusId &&
                        <aside className='px-3 py-1 rounded-md text-sm' style={{ color: "#ff8503", backgroundColor: "#faebdc" }} >
                            Pending
                        </aside> 
                    }
                   
                </div>
            </div>
        </div>
  )
}

export default SessionBox