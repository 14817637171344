import React, { useEffect, useState ,useRef} from "react";
import { IoAdd } from "react-icons/io5";
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Modal } from "@mui/material";
import AddPaymentModal from "../components/payments/add_payment_modal";
import { useGetPaymentsQuery } from "../redux/api/paymentSlice";
import PaymentBox from "../components/payments/payment_box";
import { FaArrowUpLong } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import TimeSelectorComponent from "../components/calendar/time_selector_component";
import CustomPieChart from "../components/charts/custom_pie_chart";
import { FaRegHourglass } from "react-icons/fa6";
import LoadingPage from "./loading_page";
import { PAGE_LIMIT } from "../utils/constants";

const PaymentPage = () => {
  const addButtonRef = useRef(null);
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().startOf("day"));
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false); 
  const {
    data: paymentResponse,
    isLoading: isPaymentsLoading,
    error: paymentsError,
  } = useGetPaymentsQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page,
    limit: PAGE_LIMIT,
  });


  const resetPaginationVars = () => {
    setPage(1);
  }

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (paymentsError) return;

    if (page == 1) {
      setPayments(paymentResponse?.transactions);
    } else {
      setPayments((prevPayments => [...prevPayments, ...paymentResponse?.transactions]))
    }
    setIsFetchingMore(false);
  }, [paymentResponse]);


  if (isPaymentsLoading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="max-w-5xl w-full px-3">
          <section>
            <div className="w-full flex justify-between">
              <p className="text-lg font-bold mb-4">Payments</p>
              <div
                id="add_payment"
                className="w-6 h-6 bg-main flex items-center cursor-pointer hover:opacity-80 justify-center rounded-full"
                onClick={() => setIsModalOpen(true)}
              >
                <IoAdd color="white" size={20} />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 lg:grid-cols-4 mt-3 justify-between">
              <div className="flex items-center">
                <div className="w-8">
                  <FaRegHourglass color="orange" size={16} />
                </div>
                <p>Total Pending: </p>
                <p className="ml-2 text-orange-500">
                  {paymentResponse?.total_pending_amount_formatted}
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-8">
                  <FaUsers color="orange" size={23} />
                </div>
                <p>Pending Users: </p>
                <p className="ml-2 text-orange-500">
                  {paymentResponse?.total_pending_users}
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-8">
                  <FaArrowUpLong color="green" size={16} />
                </div>
                <p>Total Income: </p>
                <p className="ml-2 text-green-600">
                  {paymentResponse.total_income_formatted}
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-8">
                  <FaUsers color="green" size={23} />
                </div>
                <p>Total Users: </p>
                <p className="ml-2 text-green-600">
                  {paymentResponse.total_trainees}
                </p>
              </div>
            </div>
          </section>

          <section className="my-10 flex flex-col lg:flex-row">
            <aside className="flex flex-col md:items-center md:flex-row lg:flex-col flex-1 justify-center md:justify-around lg:justify-start">
              <div className="flex flex-col w-full md:flex-row md:justify-around lg:flex-col lg:justify-start">
                <div>
                  <p className="font-semibold my-5">
                    Pending Amounts by Users:
                  </p>
                  <CustomPieChart
                    data={paymentResponse?.pending_users?.map(
                      (transaction) => ({
                        value: transaction.balance * -1,
                        label: `${transaction.trainee?.firstname} ${transaction.trainee?.lastname}`,
                        displayValue: transaction.balance_formatted,
                        color: transaction.trainee?.color,
                      })
                    )}
                  />
                </div>

                <div className="hidden md:block">
                  <p className="my-5 font-semibold">
                    Pending Sessions by Users:
                  </p>
                  <CustomPieChart
                    data={paymentResponse?.pending_users?.map(
                      (transaction) => ({
                        value: transaction.pending_sessions,
                        label: `${transaction.trainee?.firstname} ${transaction.trainee?.lastname}`,
                        color: transaction.trainee?.color,
                        displayValue: transaction.pending_sessions,
                      })
                    )}
                  />
                </div>
              </div>
            </aside>

            <main className="w-full mt-14 flex-1 lg:mt-0">
              <TimeSelectorComponent
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                onDateChanged={() => resetPaginationVars()}
              ></TimeSelectorComponent>
              <div className="flex items-center justify-center flex-col mt-7 gap-y-4"> 
                {payments?.length > 0 ? (
                  <>
                    <div className="w-full max-w-96">
                      <p className="text-start font-semibold">Total transactions: {paymentResponse?.total_transactions}</p>
                    </div>
                    {payments?.map((transaction, index) => (
                      <PaymentBox key={index} payment={transaction} />
                    ))}
                    {
                      paymentResponse?.current_page < paymentResponse?.pages && 
                      <button 
                        className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                        onClick={() => {
                          setPage((page) => page + 1);
                          setIsFetchingMore(true);
                        }}
                      >
                        {
                          isFetchingMore ?
                          <CircularProgress size={23} sx={{ color: 'white' }} />
                          : 
                          'Load More'
                        }
                      </button>
                    }
                  </>
                ) : (
                  <p className="text-sm">
                    No payments have been made in this date
                  </p>
                )}
              </div>
            </main>
          </section>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <AddPaymentModal 
            onClose={() => setIsModalOpen(false)} 
            resetPaginationVars={resetPaginationVars}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PaymentPage;
