
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setExpenseId } from '../../redux/storeSlice';

const ExpensesBox = ({expense}) => {
  
  return (
       <div className='max-w-96 w-full border gap-x-5  px-4 py-2 rounded-lg flex justify-between shadow-md'>        
        <div className='flex flex-1 items-center gap-x-3'>
     <div className='flex items-center w-full'>
        <p className='text-red-600 font-semibold text-md'>{expense?.amount_formatted}</p>
        <p className='font-semibold text-md ml-auto'>{expense?.details}</p>
      </div>
        </div>
      </div>
  )
}

export default ExpensesBox