import dayjs from "dayjs";
import { apiSlice } from "./apiSlice";

export const expensesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
       
        createExpenses: builder.mutation({
            query: (newExpenses) => ({
                url: 'coach/expense',
                method: 'POST',
                body: newExpenses,
            }),
            invalidatesTags: [{ type: 'Expenses' }],
        }),
        editExpenses: builder.mutation({
          query: (expense) => {
            console.log('Expense object received:', expense); 
            return {
              url: `coach/expense/${expense.expense_id}`,
              method: 'PUT',
              body: expense,
            };
          },
          invalidatesTags: [{ type: 'Expenses', expense_id: 'LIST' }],
        }),
        getExpenses: builder.query({
            query: ({startDate, endDate, limit, page}) => {
                const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
                const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
                return `https://clickcoach.app/index.php?route=rest/coach/expense&date_start=${formattedStartDate}&date_end=${formattedEndDate}&page=${page}&limit=${limit}`;
            },
            transformResponse: (response) => response.data,
            providesTags: ['Expenses'],
            invalidatesTags: [{ type: 'Expenses' }],
        }),
        deleteExpenses: builder.mutation({
            query: (expense_id) => ({
              url: `coach/expense/${expense_id}`,
              method: 'DELETE',
            }),
            invalidatesTags: (result, error, { expense_id }) =>  [{ type: 'Expenses' }],
          }),
          
    })
})

export const { useCreateExpensesMutation,useEditExpensesMutation, useDeleteExpensesMutation,  useGetExpensesQuery } = expensesSlice;
