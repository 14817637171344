import React, { useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import CustomRadioButton from "../../components/inputs/radio/radio_btn";
import PhoneInput from "react-phone-number-input";
import ErrorPopup from "../common/error_popup";
import {
  useAddTraineeMutation,
  useDeleteTraineeMutation,
  useEditTraineeMutation,
} from "../../redux/api/traineeApi";
import { CircularProgress, TextField } from "@mui/material";
import { getMessageFromError } from "../../utils/utils";
import SubTraineesBox from "./subtrainees_box";
import FormSubmitBtn from "../buttons/form_submit_btn/form_submit_btn";

const AddTraineeModal = ({ trainee, onClose }) => {
  const id = trainee?.customer_id;
  const [firstName, setFirstName] = useState(trainee?.firstname ?? "");
  const [lastName, setLastName] = useState(trainee?.lastname ?? "");
  const [phone, setPhone] = useState(trainee?.telephone ?? "");
  const [gender, setGender] = useState(trainee?.gender ?? "");
  const [subtrainees, setSubtrainees] = useState(
    trainee?.subtrainees?.map((subtrainee) => subtrainee.name) ?? [""]
  );
  const [color, setColor] = useState(trainee?.color ?? "#ff0000");
  const [errMsg, setErrMsg] = useState("");
  
  const colorRef = useRef();

  const [addTrainee, { isLoading: isAddLoading }] = useAddTraineeMutation();
  const [editTrainee, { isLoading: isEditLoading }] = useEditTraineeMutation();
  const [deleteTrainee, { isLoading: isDeleteLoading }] =
    useDeleteTraineeMutation();

  const handleCreateTrainee = async () => {
    try {
      await addTrainee({
        firstname: firstName,
        lastname: lastName,
        telephone: phone,
        gender: gender,
        trainees: subtrainees,
        // "dob": dob,
        color: color,
      }).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleDeleteTrainee = async () => {
    try {
      await deleteTrainee(id).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleEditTrainee = async () => {
    try {
      await editTrainee({
        id: id,
        firstname: firstName,
        lastname: lastName,
        telephone: phone,
        gender: gender,
        trainees: subtrainees,
        // "dob": dob,
        color: color,
      }).unwrap();
      onClose();
    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  return (
    <div
    className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
    onClick={onClose}
  >
      <div
      className="relative mx-4  w-[400px] max-w-full overflow-y-auto bg-white rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
      onClick={(e) => e.stopPropagation()}
    >
        <div className="flex justify-between px-6 pt-6">
          <p className="font-bold text-lg">
            {trainee == null
              ? "Create Trainee Account"
              : "Edit Trainee Account"}
          </p>
          <IoIosClose
            size={30}
            className="text-gray-500 cursor-pointer"
            onClick={() => onClose()}
          />
        </div>
        <div className="p-6 h-full overflow-y-auto">
          <div className="space-y-4">
            <div>
              <label className="font-semibold">First Name</label>
              <div className="h-2"></div>
              <TextField
                value={firstName}
                placeholder="First Name"
                onChange={(e) => setFirstName(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>
            <div>
              {/* <label htmlFor="" className="font-semibold text-sm">Last Name</label> */}
              {/* <NormalInput placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} /> */}
              <label className="font-semibold">Last Name</label>
              <div className="h-2"></div>
              <TextField
                value={lastName}
                placeholder="Last Name"
                onChange={(e) => setLastName(e.target.value)}
                variant="outlined"
                fullWidth
              />
            </div>
            <div>
              <label htmlFor="" className="font-semibold text-sm">
                Phone Number
              </label>
              <PhoneInput
                className="phone-input-custom mt-2"
                placeholder="phone number"
                defaultCountry="LB"
                value={phone}
                style={{
                  border: 0,
                  borderRadius: "3px",
                  paddingLeft: "10px",
                  paddingTop: "3px",
                  border: "1.2px solid #D1D5DB",
                }}
                onChange={setPhone}
              />
            </div>
            <div className="flex items-center justify-start mb-4">
  <label className="text-gray-700 font-semibold w-1/3" htmlFor="gender">Gender</label>
  <select
    id="gender"
    className="border border-gray-300 rounded-lg px-3 py-2 w-2/3 focus:ring-2 focus:ring-indigo-500 focus:outline-none shadow-sm transition-shadow duration-200"
    value={gender}
    onChange={(e) => setGender(e.target.value)}
  >
    <option value="" disabled>Select gender</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
  </select>
</div>


            <div>
              <label className="font-semibold">Color</label>
              <input
                ref={colorRef}
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                className="w-0 h-0 p-0 m-0 cursor-pointer"
              />
              <div
                className="w-12 h-12 rounded-full cursor-pointer border-2"
                style={{ backgroundColor: color }}
                onClick={() => colorRef.current?.click()}
              ></div>
              <ErrorPopup
                message={errMsg}
                isVisible={errMsg?.length > 0}
                onClose={() => setErrMsg("")}
              />
            </div>

            <SubTraineesBox
              subtrainees={subtrainees}
              setSubtrainees={setSubtrainees}
            />
            <div className="flex justify-end my-3 space-x-2">
              {trainee == null ? (
                <FormSubmitBtn
                  onClick={handleCreateTrainee}
                  text="Create"
                  isLoading={isAddLoading}
                />
              ) : (
                <>
                  <button
                    onClick={handleDeleteTrainee}
                    className="bg-red-600 text-white px-5 py-1 rounded-md hover:opacity-80"
                  >
                    {!isDeleteLoading ? (
                      "Delete"
                    ) : (
                      <CircularProgress size={20} color="inherit" />
                    )}
                  </button>

                  <FormSubmitBtn
                    onClick={handleEditTrainee}
                    text="Edit"
                    isLoading={isEditLoading}
                  />
                </>
              )}
            </div>
            <div className="h-7"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTraineeModal;
