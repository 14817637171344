import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import React from "react";

const CustomPieChart = ({ data, radius = 120, height}) => {
  return (
    <PieChart
      series={[
        {
          arcLabel: (item) => `${item.displayValue}`,
          arcLabelMinAngle: 15,
          data,
          outerRadius: radius,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
          fontWeight: "bold",
          fontSize: 14,
          transform: "rotate(-45deg)",
          transformOrigin: "center",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          textAnchor: "middle",
          dominantBaseline: "middle",
        },
      }}
      slotProps={{
        legend: {
          labelStyle: {
            tableLayout: 'fixed',
          },
          direction: 'row',
          position: {
            horizontal: 'middle',
            vertical: 'bottom',
          },
        },
      }}
      margin={{ right: 0, top: 0, bottom: 70 }}
      width={2 * radius + 20}
      height={height ?? 2 * radius * 1.5}
    />
  );
};

export default CustomPieChart;
