import React, { useState, useEffect, useRef } from 'react';
import { IoIosClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useParams } from 'react-router-dom';
import { useGetSessionsByUserQuery } from '../../redux/api/sessionApi';
import SessionBox from '../sessions/session_box';

const PrintInvoiceModal = ({ onClose, trainee }) => {
  const { id } = useParams();
  const [sessions, setSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const { data: sessionsResponse, isLoading: isSessionsLoading } = useGetSessionsByUserQuery({
    traineeId: id,
    page: 1,
    limit: 1000,
  });
  const store = useSelector(state => state.store.data);

  const paidStatusId = store.session_statuses.find(session => session.name.toLowerCase() === 'paid').session_status_id;
  const pendingStatusId = store.session_statuses.find(session => session.name.toLowerCase() === 'pending').session_status_id;
  const [errMsg, setErrMsg] = useState('');
  const invoiceRef = useRef(null);

  useEffect(() => {
    setSessions(sessionsResponse?.sessions || []);
  }, [sessionsResponse]);

  const handleSessionSelect = (session) => {
    setSelectedSessions((prevSelected) => {
      if (prevSelected.includes(session)) {
        return prevSelected.filter((s) => s !== session);
      } else {
        return [...prevSelected, session];
      }
    });
  };

  const handlePrint = () => {
    const printContents = invoiceRef.current.innerHTML;
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            .invoice {
              max-width: 800px;
              margin: auto;
              border: 1px solid #ddd;
              padding: 20px;
              border-radius: 10px;
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            }
            .invoice h3 {
              text-align: center;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            table, th, td {
              border: 1px solid #ddd;
            }
            th, td {
              padding: 10px;
              text-align: left;
            }
            th {
              background-color: #f4f4f4;
            }
          </style>
        </head>
        <body>
          <div class="invoice">
            ${printContents}
          </div>
        </body>
      </html>
    `);
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    newWindow.close();
  };

  const calculateTotalPriceByMonth = () => {
    const monthYearMap = {};
    selectedSessions.forEach((session) => {
      const date = new Date(session.date);
      const key = `${date.getMonth() + 1}-${date.getFullYear()}`;
      if (!monthYearMap[key]) {
        monthYearMap[key] = 0;
      }
      const price = parseFloat(session.price) || 0;
      monthYearMap[key] += price;
    });
    return monthYearMap;
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  };
  const names = selectedSessions
  .flatMap(session => session.subtrainees.map(subtrainee => subtrainee.name))
  .join(', ');


  const renderSessionDetails = () => {
    const monthYearMap = calculateTotalPriceByMonth();
    return (
      <table className="w-full">
        <thead>
          <tr>
          <th>Trainees</th>
            <th>Date</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {selectedSessions.map((session, index) => {
            const date = formatDate(session.date);
            const price = parseFloat(session.price) || 0;
            const status = session.status;
            const statusLabel =
              status === 'Paid' ? (
                <td className="text-green-500">Paid</td>
              ) : (
                status === 'Pending' && (
                  <td className="text-orange-500">Pending</td>
                )
              );

            return (
              <tr key={index}>
                <td>{names}</td>
                <td>{date}</td>
                <td>{`$${price.toFixed(2)}`}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderMonthlyTotal = () => {
    const monthYearMap = calculateTotalPriceByMonth();
    return (
      <table className="w-full mt-4">
        <thead>
          <tr>
            <th>Month-Year</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(monthYearMap).map(([key, total], index) => (
            <tr key={index}>
              <td>{key}</td>
              <td>{`$${total.toFixed(2)}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const sessionStatusId = sessionsResponse?.sessions?.session_status_id;
  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-40 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-8 py-6 w-[1000px] max-w-full h-auto max-h-[95vh] overflow-y-auto bg-white rounded-xl shadow-2xl transform transition-all duration-300 ease-in-out"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6 pb-2 border-b border-gray-300">
          <h2 className="font-bold text-2xl text-gray-900">Print Invoice</h2>
          <IoIosClose
            size={35}
            className="text-gray-500 cursor-pointer hover:text-red-500 transition-colors duration-200"
            onClick={onClose}
          />
        </div>

        
          <p className="font-semibold text-lg mb-3">
            Sessions ({sessionsResponse?.total_sessions})
          </p>
       
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 overflow-y-auto max-h-[300px] mb-4">
          {sessions.map((session, index) => (
            session.session_status_id === paidStatusId && (
              <div key={index} className="flex items-center text-xs">
                <input
                  type="checkbox"
                  id={`session-${session.id}`}
                  className="mr-2"
                  checked={selectedSessions.includes(session)}
                  onChange={() => handleSessionSelect(session)}
                />
                <label htmlFor={`session-${session.id}`}>
                  <SessionBox session={session} className="w-32 h-12" /> {/* Modify the size here */}
                </label>
              </div>
            )
          ))}
        </div>

        {/* Invoice Content (Hidden) */}
        <div className="mt-6 hidden" ref={invoiceRef}>
          <h3 className="font-bold text-xl">Invoice Details</h3>
          <h3 className="font-bold text-md mt-4">Trainee: {trainee.firstname} {trainee.lastname}</h3>
          <h3 className="font-bold text-md mt-4">Sessions Paid: {selectedSessions.length}</h3>
          <h4 className="font-bold text-md mt-4">Session Details:</h4>
          {renderSessionDetails()}
        </div>

        {/* Print Button */}
        <div className="flex justify-end items-center">
          <FormSubmitBtn
            text="Print"
            onClick={() => {
              if (selectedSessions.length === 0) {
                setErrMsg('Please select at least one session to print the invoice.');
              } else {
                setErrMsg('');
                handlePrint();
                onClose();
              }
            }}
          />
        </div>

        {/* Error Message */}
        {errMsg && <p className="text-red-500 mt-4 text-center font-semibold">{errMsg}</p>}
      </div>
    </div>
  );
};

export default PrintInvoiceModal;
