import { MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosClose } from 'react-icons/io';
import { useGetTraineesQuery } from '../../redux/api/traineeApi';
import FormSubmitBtn from '../buttons/form_submit_btn/form_submit_btn';
import { useCreatePaymentMutation } from '../../redux/api/paymentSlice';
import { getMessageFromError } from '../../utils/utils';
import { setShowAdd } from '../../redux/storeSlice';

const AddPaymentModal = ({ onClose, resetPaginationVars }) => {
  let trainee = useSelector((state) => state.store.trainee);
  const dispatch = useDispatch();
  const [selectedTrainee, setSelectedTrainee] = useState(trainee); 
  const [amount, setAmount] = useState(''); 
  const [note, setNote] = useState('');  
  const [errMsg, setErrMsg] = useState('');
  const { data: traineesResponse, isLoading: isTraineesLoading } = useGetTraineesQuery();
  const [trainees, setTrainees] = useState([]);
  const [addPayment, { isLoading }] = useCreatePaymentMutation();

  useEffect(() => {
    setTrainees(traineesResponse?.trainees);
  }, [traineesResponse]);

  const handleAddPayment = async () => {
    try {
      await addPayment({
        trainee_id: selectedTrainee?.customer_id, 
        amount: amount,
        note: note,
      }).unwrap();
      resetPaginationVars();
      onClose();
      dispatch(setShowAdd(false));

    } catch (error) {
      setErrMsg(getMessageFromError(error));
    }
  };

  const handleTraineeChange = (event) => {
    const trainee = trainees.find(t => t.customer_id === event.target.value);
    setSelectedTrainee(trainee); 
  };

  return (
    <div
      className="fixed inset-0 py-5 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="relative mx-4 px-7 py-6 w-[500px] overflow-y-auto bg-white rounded-lg overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between">
          <p className="font-bold text-lg">Add New Payment</p>
          <IoIosClose
            size={30}
            className="text-gray-500 cursor-pointer"
            onClick={() => onClose()}
          />
        </div>
        <div className="h-4"></div>
        <article>
          <label className="font-semibold mt-3">Trainee</label>
          <div className="h-2"></div>
          <Select
            fullWidth
            value={selectedTrainee?.customer_id || ''}  // Ensure value is never undefined
            onChange={handleTraineeChange}  // Update to handle the entire trainee object
          >
            {trainees?.map((trainee, index) => (
              <MenuItem key={index} value={trainee.customer_id}>
                {trainee.firstname} {trainee.lastname}
              </MenuItem>
            ))}
          </Select>
          { selectedTrainee != null && (
            <p className="mt-2 text-sm">
                Trainee Balance: {selectedTrainee?.balance}
            </p>
          )
          }
        </article>
        <article className="mt-3">
          <label className="font-semibold">Amount</label>
          <div className="h-2"></div>
          <TextField
            value={amount || ''}  // Ensure value is never undefined
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
            type="number"
            inputProps={{ min: 1 }}
            fullWidth
          />
        </article>
        <article className="mt-3">
          <label htmlFor="" className="font-semibold">Note</label>
          <div className="h-2"></div>
          <TextField
            value={note || ''}  // Ensure value is never undefined
            onChange={(e) => setNote(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </article>
        <div className="flex justify-end mt-5">
          <FormSubmitBtn
            text="Add Payment"
            isLoading={isLoading}
            onClick={() => handleAddPayment()}
          />
        </div>
        {errMsg && <p className="text-red-500 mt-3 text-center">{errMsg}</p>}
      </div>
    </div>
  );
};

export default AddPaymentModal;
