import React, { useState, useEffect } from 'react';
import { IoCloseCircle } from "react-icons/io5";

const ErrorPopup = ({ message, isVisible, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => onClose(), 3000); // Automatically close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        minWidth: "400px",
        left: '50%',
        display: "flex",
        transform: 'translateX(-50%)',
        backgroundColor: '#f44336', 
        color: 'white',
        padding: '16px',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
      }}
    >
      <IoCloseCircle size={25} className='mr-2'/>
      {message}
    </div>
  );
};

export default ErrorPopup;
