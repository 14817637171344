import React, { useEffect, useState } from "react";
import { CircularProgress, Modal, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useGetIncomesQuery } from "../../src/redux/api/income";
import { useGetExpensesQuery } from "../../src/redux/api/expenses";
import IncomeBox from "../components/incomes/income_box";
import ExpensesBox from "../components/expenses/expenses_box";
import { useDispatch, useSelector } from 'react-redux';
import AddExpensesModal from "../components/expenses/add_expenses";
import TimeSelectorComponent from "../components/calendar/time_selector_component";
import { PAGE_LIMIT } from "../utils/constants";
import LoadingPage from "./loading_page";
import { IoAdd } from "react-icons/io5";


const Profit = () => {
  const hideEdit = useSelector(state => state.store.hideEdit);
  const [activeTab, setActiveTab] = useState("income");
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().startOf("day"));
  const [expensesPage, setExpensesPage] = useState(1);
  const [incomePage, setIncomePage] = useState(1);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const {
    data: incomesResponse,
    isLoading: isincomesLoading,
    error: incomesError,
  } = useGetIncomesQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page: incomePage,
    limit: PAGE_LIMIT,
  });

  const resetPaginationVars = () => {
    setExpensesPage(1);
    setIncomePage(1);
  };

  const handleRowClick = (expenses) => {
    setSelectedExpenses(expenses);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedExpenses(null);
  };

  const {
    data: expensesResponse,
    isLoading: isexpensesLoading,
    error: expensesError,
  } = useGetExpensesQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    page: expensesPage,
    limit: PAGE_LIMIT,
  });

  const [isFetchingIncomesMore, setIsFetchingIncomesMore] = useState(false);
  const [isFetchingExpensesMore, setIsFetchingExpensesMore] = useState(false);

  useEffect(() => {
    if (incomesError) return;

    if (incomePage === 1) {
      setIncomes(incomesResponse?.income);
    } else {
      setIncomes((previncomes) => [
        ...previncomes,
        ...incomesResponse?.income,
      ]);
    }
    setIsFetchingIncomesMore(false);
  }, [incomesResponse]);

  useEffect(() => {
    if (expensesError) return;

    if (expensesPage === 1) {
      setExpenses(expensesResponse?.expenses);
    } else {
      setExpenses((prevexpenses) => [
        ...prevexpenses,
        ...expensesResponse?.expenses,
      ]);
    }
    setIsFetchingExpensesMore(false);
  }, [expensesResponse]);

  if (isincomesLoading || isexpensesLoading) {
    return <LoadingPage />;
  }

  return (
      <div className="max-w-5xl mx-auto bg-white p-4 rounded-lg shadow-md border border-gray-100">
        <div className="flex justify-center mb-4">
          <button
            className={`mx-2 px-4 py-2 ${
              activeTab === "income"
                ? "font-bold text-green-500 border-b-2 border-green-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("income")}
          >
            INCOME
          </button>
          <button
            className={`mx-2 px-4 py-2 ${
              activeTab === "expenses"
                ? "font-bold text-red-500 border-b-2 border-red-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("expenses")}
          >
            EXPENSES
          </button>
        </div>

        <div>
          {activeTab === "income" && (
            <main className="flex flex-col md:items-center mt-10 lg:mt-0 lg:flex-col flex-1 justify-center md:justify-around lg:justify-start">
            
        <TimeSelectorComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onDateChanged={() => {
            resetPaginationVars();
            setIncomes([]);
          }}
        />

              <div className="flex flex-col items-center gap-y-6 py-6">
                {isincomesLoading && incomePage === 1 ? (
                  <div>Loading...</div>
                ) : incomes?.length === 0 && incomePage === 1 ? (
                  <div className="text-center text-sm">
                    You have no incomes scheduled for this date
                  </div>
                ) : (
                  <>
                    {incomes?.map((income, index) => (
                      <div key={index} className="w-60">
                        <IncomeBox income={income} />
                      </div>
                    ))}
                    {console.log(incomesResponse)}
                    {incomesResponse.current_page < incomesResponse.pages && (
                      <button
                        className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                        onClick={() => {
                          setIncomePage((page) => page + 1);
                          setIsFetchingIncomesMore(true);
                        }}
                      >
                        {isFetchingIncomesMore ? (
                          <CircularProgress size={23} sx={{ color: "white" }} />
                        ) : (
                          "Load More"
                        )}
                      </button>
                    )}
                  </>
                )}
                {isincomesLoading && incomePage > 1 && <CircularProgress />}
              </div>
            </main>
          )}
          {activeTab === "expenses" && (
            <main className="flex flex-col md:items-center mt-10 lg:mt-0 lg:flex-col flex-1 justify-center md:justify-around lg:justify-start">
              
        <TimeSelectorComponent
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onDateChanged={() => {
            resetPaginationVars();
            setIncomes([]);
          }}
        />

              <div className="flex items-center justify-center mt-5">
                <div
                  className="w-6 h-6 bg-main flex items-center cursor-pointer hover:opacity-80 justify-center rounded-full"
                  onClick={() => handleRowClick(null)}
                >
                  <IoAdd color="white" size={20} />
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-4 py-6">
                {isexpensesLoading && expensesPage === 1 ? (
                  <div>Loading...</div>
                ) : expenses?.length === 0 && expensesPage === 1 ? (
                  <div className="text-center text-sm">
                    You have no expenses scheduled for this date
                  </div>
                ) : (
                  <>
                    {expenses?.map((expense, index) => (
                      <div
                        key={index}
                        className="w-60"
                        onClick={() => handleRowClick(expense)}
                      >
                        <ExpensesBox expense={expense} />
                      </div>
                    ))}
                    {expensesResponse.current_page < expensesResponse.pages && (
                      <button
                        className="bg-main px-3 py-1 text-sm mt-3 rounded text-white"
                        onClick={() => {
                          setExpensesPage((page) => page + 1);
                          setIsFetchingExpensesMore(true);
                        }}
                      >
                        {isFetchingExpensesMore ? (
                          <CircularProgress size={23} sx={{ color: "white" }} />
                        ) : (
                          "Load More"
                        )}
                      </button>
                    )}
                  </>
                )}
                {isexpensesLoading && expensesPage > 1 && <CircularProgress />}
              </div>
            </main>
          )}
        </div>
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          expense={selectedExpenses}
        >
          <div>
            <AddExpensesModal
              expense={selectedExpenses}
              onClose={handleCloseModal}
              resetPaginationVars={resetPaginationVars}
            />
          </div>
        </Modal>

      </div>
  );
};

export default Profit;
