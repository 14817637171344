import { useState } from 'react';
import { IoEye, IoEyeOff } from "react-icons/io5";

const NormalInput = ({
  value,
  onChange,
  placeholder,
  digitsOnly,
  isPassword,
  inputType = 'text', 
}) => {
  // State to manage visibility for password
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Toggle visibility function
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  // Determine input type based on the parameters
  const type = digitsOnly
    ? 'number'
    : isPassword
    ? isPasswordVisible
      ? 'text'
      : 'password'
    : inputType;

  return (
    <div className="relative w-full">
      <input
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        className="block rounded-lg px-2.5 pb-2.5 pt-2.5 w-full text-sm text-gray-900 bg-white border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 peer"
      />
      {isPassword && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex items-center pr-3"
        >
          {!isPasswordVisible ? <IoEye size={20} /> : <IoEyeOff size={20} />}
        </button>
      )}
    </div>
  );
};

export default NormalInput;
