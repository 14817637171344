import React, { useState } from 'react';
import TimeSelectorComponent from '../components/calendar/time_selector_component';
import dayjs from 'dayjs';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useGetDashboardSessionsQuery } from '../redux/api/sessionApi';

const DashboardPage = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().startOf('day'));

  const { data: sessions, isLoading: isSessionsLoading } = useGetDashboardSessionsQuery({
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  const data = sessions?.map((session) => ({
    value: session?.sessions_by_trainee,
    label: `${session?.trainee?.firstname || 'Unknown'} ${session?.trainee?.lastname || ''}`.trim(),
    color: session?.trainee?.color,
  })) || [];

  // Inline styles for responsive width
  const chartStyles = {
    width: '100%',
    maxWidth: '600px', // Default width for larger screens
    height: '500px',
  };

  const smallScreenChartStyles = `
    @media (max-width: 639px) {
      .responsive-pie-chart {
        width: 400px !important; /* Adjust width for small screens */
      }
    }
  `;

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='w-full max-w-2xl'>
        <p className='mb-5 font-bold text-lg pl-14'>My Sessions</p>
        <TimeSelectorComponent 
          setStartDate={setStartDate} 
          setEndDate={setEndDate} 
          onDateChanged={() => {}}
        />
        {isSessionsLoading ? (
          <p>Loading...</p>
        ) : data.length > 0 ? (
          <div className="relative">
            <style>{smallScreenChartStyles}</style>
            <div className='w-full h-auto responsive-pie-chart'>
              <PieChart
                series={[
                  {
                    arcLabel: (item) => `${item.label} (${item.value})`,
                    arcLabelMinAngle: 65,
                    data,
                    outerRadius: 140,
                    color: (item) => item.color,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                    fontWeight: 'bold',
                    transform: 'rotate(-45deg)',
                    transformOrigin: 'center',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textAnchor: 'middle',
                    dominantBaseline: 'middle',
                  },
                }}
                style={chartStyles} // Apply styles to the PieChart
              />
            </div>
          </div>
        ) : (
          <p className='text-center mt-5 text-sm'>No Sessions on this date</p>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
