import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DraggableButton from '../components/buttons/draggable_button/draggable_button'
import { useSelector } from 'react-redux';

const WelcomePage = () => {
  let navigate = useNavigate();

  return (
    <div className='px-5 h-screen flex flex-col items-center overflow-hidden'>
        <div className='flex items-center justify-center h-screen max-w-5xl'>
            <p className='text-4xl text-center font-bold'>
                WELCOME TO THE #1 COACHING PLATFORM DESIGNED FOR ALL SPORTS COACHES
            </p>
        </div>
        <DraggableButton title="LET'S GET STARTED" onClick={() => navigate('/intro')} />
        <div className='h-24'></div>
    </div>
            
  )
}

export default WelcomePage